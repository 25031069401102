import Vue from 'vue'
import Vuex from 'vuex'
import {Server} from './api/server'

Vue.use(Vuex)

const state = {
  sidebarShow: localStorage.sidebarSize ? localStorage.sidebarSize : window.innerWidth < 480 ? false : 'responsive',
  updateHack: true,
  sidebarMinimize: false,
  settings: {
    notificationHideTimeout: 2000,
    // telMask: '+7 (###) ###-##-##',
  },
  masks: ['+## (###) ###-##-##'],
  universalMasks: ['+# (###) ###-##-##', '+## (###) ###-##-##'],
  months: [
    {month: 'Январь', value: '1'},
    {month: 'Февраль', value: '2'},
    {month: 'Март', value: '3'},
    {month: 'Апрель', value: '4'},
    {month: 'Май', value: '5'},
    {month: 'Июнь', value: '6'},
    {month: 'Июль', value: '7'},
    {month: 'Август', value: '8'},
    {month: 'Сентябрь', value: '9'},
    {month: 'Октябрь', value: '10'},
    {month: 'Ноябрь', value: '11'},
    {month: 'Декабрь', value: '12'},
  ],
  sourcesFrom: [
    {text: 'Не выбрано', value: ''},
    {text: 'IOS приложение', value: 'ios'},
    {text: 'Android приложение', value: 'android'},
    {text: 'Сайт', value: 'site'},
    {text: 'Звонок', value: 'call'},
    {text: 'VK', value: 'vk'},
    {text: 'Whatsapp', value: 'whatsapp'},
    {text: 'Telegram', value: 'telegram'},
    {text: 'Отдел контроля качества', value: 'feedback'}
  ],
  // cleanerSources: ['', 'Авито', 'HH', 'Наружная реклама', 'Камелот', 'От друзей', 'Инстаграм', 'Фарпост', 'JCat', 'ВКработа', 'SuperJob', 'Работа.ру', 'Telegram'],
  clientSources: ['', 'Звонок', 'Мессенджеры'],
  server: new Server(),
  updatingInfo: {
    task: {},
    translation: {},
  },
  orderCleaners: [],
  user: {
    cleanersWorktime: [],
    cleanerSources: [],
    services: {},
    cities: [],
    adv: [],
    advAll: [],
    price: {},
    city: {
      country: {}
    },
    rights: []
  },
  order_stats_obj: {
    'without_conflict': {
      tip: 'Без конфликтов',
      icon: 'check',
    },
    'money_back': {
      tip: 'Возврат денег',
      icon: 'cash-refund',
    },
    'bonus': {
      tip: 'Начисление бонусов',
      icon: 'star-four-points-small',
    },
    'compensation': {
      tip: 'Компенсировано',
      icon: 'cash-sync',
    },
    'reorder': {
      tip: 'Оформлено переуборок',
      icon: 'silverware-clean',
    },
  },
  newLeads() {
    return ['dmitrov', 'klg'].includes(this.user.city.code);
  },
  getPrice() {
    return this.user.price ? this.user.price : {}
  },
  findAdv(id) {
    let adv = this.user.advAll.find(a => a.n_id == id);
    return adv ? adv.title : '';
  },
  downloadCsv(rows) {
    let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(",")).join("\n");
    window.open(encodeURI(csvContent));
  },
  setMask(phone, useUniversalMask = false) {
    let masks = {...(useUniversalMask ? this.universalMasks : this.masks)};
    if (!phone) return masks[Object.keys(masks).length - 1];
    if (masks.length === 1) return masks[0];
    let digits = phone.replace(/[^0-9]/g, "");
    if (+digits[0] === 9 && masks[1]) return masks[1];
    for (let i in masks) if (masks[i].split('#').length - 1 === digits.length) return masks[i];
    return masks[0];
  },
  rand(from, to) {
    return Math.random() * (to - from) + from;
  },
  getObjectsField(objects, field = 'id') {
    let result = [];
    objects.forEach(o => result.push(o[field]))
    return result;
  },
  getCityById(id) {
    if (!this.user.allCities) return {};
    return this.user.allCities.find(city => city.id === id) || {};
  },
  toSelectItems(arrayOrObject, field = '', valueField = '') {
    let result = [];
    for (let i in arrayOrObject) result.push({
      value: valueField ? arrayOrObject[i][valueField] : i,
      text: field ? arrayOrObject[i][field] : arrayOrObject[i]
    });
    return result;
  },
  leadInappropriateType: [
    '',
    'По заказу',
    'Спам',
    'По вакансии',
    'Другое',
  ],
  leadStatuses: [
    {title: 'Не указано', color: ''},
    {title: 'Заказ', color: '#d0ffd0'},
    {title: 'Ожидание', color: '#ffe8be'},
    {title: 'Отказ', color: '#ffe2e2'},
    {title: 'Нецелевой', color: '#dfdfdf'},
  ],
  leadStatuses2: [
    {text: '', value: 0},
    {text: 'Дорого', value: 1},
    {text: 'Нет клинеров', value: 2},
    {text: 'Другое', value: 3},
  ],
  notifications: {},
  lastID: 0,
  systemNotificationID: 0,
  unseen: 0,
  orderStatuses: [
    {label: 'Новый', value: '0'},
    {label: 'Оформлен', value: '1'},
    {label: 'Выполняется', value: '2'},
    {label: 'Оплачен', value: '5'},
    {label: 'Отменен', value: '4'},
    {label: 'На подтверждение', value: '6'},
    {label: 'Подтвержден', value: '7'},
    {label: 'Проблема', value: '8'},
    {label: 'Ждём оплату', value: '9'},
  ],
  cleanTypes: {
    standart: 'Быстрая',
    general: 'Генеральная',
    remont: 'После ремонта',
    other: 'Другое',
  },
  fireReasons: ['Неудовлетворенность зп',
    'Неудовлетворенность кол-вом заказов',
    'Заказы расположены далеко друг от друга/не удобно ездить',
    'Сложности в коллективе',
    'Отъезд',
    'Болезнь',
    'Решение компании']
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
    localStorage.sidebarSize = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
    localStorage.sidebarSize = sidebarClosed ? true : 'responsive'
  },
  setOrderCleaners(state, cleaners) {
    state.orderCleaners = cleaners;
  },
  updatingInfo(state, value) {
    state.updatingInfo = value
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  setSeen(state, [notification, type]) {
    console.log('setSeen', JSON.parse(JSON.stringify([notification, type])));
    if (type !== 'hide') state.server.request('user/seen', {last_id: notification.id, after_id: notification.id})
    state.notifications[notification.id].hidden = 1;
    state.notifications[notification.id].datetime_seen = 1;
    state.notifications = {...state.notifications}
    state.unseen--;
    console.log(JSON.parse(JSON.stringify(state.notifications)))
  },
  setSeenAll(state) {
    state.server.request('user/seen', {last_id: state.lastID})
    for (let i in state.notifications) {
      if (Number(i)) state.notifications[i].datetime_seen = 1;
    }
    state.notifications = {...state.notifications}
    state.unseen = 0;
  },
  addNotifications(state, notifications) {
    let timeout = 7;
    if (!notifications || !notifications.length) return;
    notifications.forEach(notification => {
      notification.hidden = state.notifications[notification.id] ? state.notifications[notification.id].hidden : notification.hidden;
      state.notifications[notification.id] = notification;
      notification.timeout = timeout;
      if (!Number(notification.datetime_seen) && !notification.hidden) setTimeout(() => {
        state.notifications[notification.id].hidden = 1;
        state.notifications = {...state.notifications}
      }, timeout * 1000)
    })
    state.notifications = {...state.notifications}
    let unseen = 0;
    for (let i in state.notifications) {
      if (Number(i) && !parseInt(state.notifications[i].datetime_seen)) unseen++;
    }
    state.unseen = unseen;
  },
}

const subscriptionState = {
  activeSubs: [],
}

const subscriptionMutations = {
  setActiveSubs: (state, newSubs) => {
    state.activeSubs.push(...newSubs);
  }
}

const subscriptionActions = {
  setActiveSubs: async (context, newSubs) => {

    context.commit('setActiveSubs', newSubs);
  }
}

export default new Vuex.Store({
  state: {...state, ...subscriptionState},
  mutations: {...mutations, ...subscriptionMutations},
  actions: {...subscriptionActions}
})
